<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="sizeModal"
    >   
        <CForm>
            
            <CRow>
              <CCol md="6">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
              <CCol md="6">
                <CSelect
                  label="Tipo de Talla"
                  :value.sync="$v.form.type.$model"
                  :options="['general', 'zapatos']"
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import sizes from '../../services/sizes';

export default {
  name: 'SizeModal',
  props: {

  },
  data () {
    return {
      sizeModal: false,
      title: "Nueva Talla",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Talla'},
        {key: 'type', label: 'Tipo'},
        {key: 'actions', label: '', class: 'actions' }
      ]
    }
  },
  mounted: async function() {
    
  },
  methods: {
    storeModal () {
      this.sizeModal = true;
      this.color = "info";
      this.title = 'Nueva Talla';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          name: '',
          type: 'General'
      }
    },
    updateModal (item) {
      this.sizeModal = true;
      this.color = "warning";
      this.title = 'Editar Talla';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          name: item.name,
          type: item.type
      };
    },
    selectProduct(item){
      
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.sizeModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      type: {
        required
      }
    }
  },
}
</script>