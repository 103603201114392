<template>
  <div>
    <CRow>
      <CCol sm="12">
        <SizesTable
          :items="sizes"
          hover
          striped
          border
          small
          fixed
          caption="Tallas"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import SizesTable from '../../components/sizes/SizesTable.vue'
import sizes from '../../services/sizes';
import store from '../../store'

export default {
  name: 'Sizes',
  components: { SizesTable },
  data: function () {
		return {
            sizes: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await sizes.get();

    if(response.type == "success"){
      this.sizes = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.sizes = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
